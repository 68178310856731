<template>
  <div class="h-full w-full">
    <div
      v-if="hasError"
      class="min-h-full min-w-full flex bg-gray-500"
    >
      <div class="flex mx-auto my-auto text-red-400 flex-row">
        <p class="font-normal text-2xl">
          <span class="font-extrabold text-6xl">ERROR</span>
          Please log out and back in
        </p>
      </div>
    </div>

    <div
      v-else
      class="h-full flex flex-col px-0 pt-2 pr-2 pb-4"
    >
      <div class="bg-gray-50 font-normal text-gray-800 px-1 mt-2 rounded-sm rounded-b-none mb-1 justify-between flex">
        <div class="flex my-auto flex-col xl:flex-row pl-2">
          <div class="block lg:hidden">
            <button
              class="flex font-normal px-2 border border-gray-500 my-2 rounded-sm"
              v-on:click.prevent="switchAutoUpdate()"
            >
              {{
                !enableAutoUpdate ? "ENABLE:AUTO_UPDATE" : "DISABLE:AUTO_UPDATE"
              }}
            </button>
          </div>
          <div class="flex">
            <span class="pr-1">L:{{ LICENSES }}</span> /
            <span class="px-1">T:{{ DEVICES.length }}</span> /
            <span class="px-1">M:{{ DEVICES_MONITORED.length }}</span> /
            <span class="px-1">N:{{
                DEVICES_MONITORED.length - DEVICES_MONITORED_RESPONDING.length
              }}</span>
          </div>
          <div class="flex whitespace-no-wrap">
            Monitored-Active (30 min.):
            <transition
              name="slide-fade"
              mode="out-in"
            >
              <span
                class="px-2"
                :key="
                  (
                    (100 / DEVICES_MONITORED.length) *
                    DEVICES_MONITORED_RESPONDING.length
                  ).toFixed(1)
                "
              >{{
                  (
                    (100 / DEVICES_MONITORED.length) *
                    DEVICES_MONITORED_RESPONDING.length
                  ).toFixed(1)
                }}%</span>
            </transition>
          </div>
          <div class="flex">
            Temps: &#8924;25°C
            <span class="font-extrabold px-2 text-green-500">
              <transition
                name="slide-fade"
                mode="out-in"
              >
                <span :key="DEVICES_COOL.length">{{
                  DEVICES_COOL.length
                }}</span>
              </transition>
            </span>
            &gt;25&#8924;35°C
            <span class="font-extrabold px-2 text-yellow-400">
              <transition
                name="slide-fade"
                mode="out-in"
              >
                <span :key="DEVICES_MEDIUM.length">{{
                  DEVICES_MEDIUM.length
                }}</span>
              </transition>
            </span>
            &gt;35°C
            <span class="font-extrabold px-2 text-red-500">
              <transition
                name="slide-fade"
                mode="out-in"
              >
                <span :key="DEVICES_HOT.length">{{ DEVICES_HOT.length }}</span>
              </transition>
            </span>
          </div>
          <div class="xl:px-3 flex flex-row  pb-2 lg:pb-1">
            <div
              v-for="mc in MEDIA_COUNTER"
              :key="mc.prefix"
              class="xl:px-1"
            >
              {{ mc.prefix }}(5m):
              <transition
                name="slide-fade"
                mode="out-in"
              >
                <span
                  class="pr-1"
                  :key="mc.count"
                >{{ mc.count }}</span>
              </transition>
            </div>
          </div>
        </div>
        <div class="hidden lg:block">
          <button
            class="flex font-normal xl:px-2 mx-1 border border-gray-500 my-2 rounded-sm"
            v-on:click.prevent="switchAutoUpdate()"
          >
            {{
              !enableAutoUpdate ? "ENABLE:AUTO_UPDATE" : "DISABLE:AUTO_UPDATE"
            }}
          </button>
        </div>
      </div>
      <div class="flex-col px-0 md:px-1 py-2 leading-7 flex-1 overflow-y-scroll border border-gray-50 bg-gray-100 rounded-sm rounded-t-none max-h-full">
        <div
          class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-1 gap-y-1"
          v-bind:class="DEVICES_UNCONFIGURED.length > 0 ? 'pb-2' : ''"
        >
          <DashboardDeviceUnconfigured
            v-for="device in DEVICES_UNCONFIGURED"
            v-bind:key="device.id"
            v-bind:device="device"
            class="text-xs"
          ></DashboardDeviceUnconfigured>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-1 gap-y-1">
          <DashboardDeviceConfigured
            v-for="device in DEVICES_CONFIGURED"
            v-bind:key="device.id"
            v-bind:device="device"
            class="text-xs"
          ></DashboardDeviceConfigured>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>

<script>
import axios from "axios";
import DashboardDeviceConfigured from "./components/DashboardDeviceConfigured";
import DashboardDeviceUnconfigured from "./components/DashboardDeviceUnconfigured";
export default {
  name: "Dashboard",
  components: { DashboardDeviceConfigured, DashboardDeviceUnconfigured },
  beforeMount() {
    this.USER_OBJECT = JSON.parse(localStorage.getItem("USER_OBJECT"));
    this.$store.commit("UPDATE_USER_OBJECT", this.USER_OBJECT);
    this.AUTH_HEADER = JSON.parse(localStorage.getItem("AUTH_HEADER"));
    this.$store.commit("UPDATE_AUTH_HEADER", this.AUTH_HEADER);
    axios.defaults.headers.common["Authorization"] = this.AUTH_HEADER;
  },
  mounted() {
    this.UpdateDashboard();
  },
  beforeDestroy() {
    clearInterval(this.dashboardUpdater);
  },
  data() {
    return {
      hasError: false,
      enableAutoUpdate: true,
      USER_OBJECT: {},
      AUTH_HEADER: "",
      DEVICES: [],
      DEVICE_INFOS: [],
      LICENSES: 60,
      MEDIA_COUNTER: [],
      dashboardUpdater: null,
    };
  },
  computed: {
    DEVICES_MONITORED: function () {
      return this.DEVICES.filter((device) => device.monitored == true);
    },
    DEVICES_UNCONFIGURED: function () {
      return this.DEVICES.filter(
        (device) => device.configstate == "UNCONFIGURED" && !device.edited
      );
    },
    DEVICES_CONFIGURED: function () {
      let LOCAL_DEVICES = this.DEVICES;
      let LOCAL_DEVICE_INFOS = this.DEVICE_INFOS;
      let MERGED = [];
      LOCAL_DEVICES.map((device) => {
        LOCAL_DEVICE_INFOS.map((info) => {
          if (device.playersn == info.playersn) {
            MERGED.push({ ...device, ...info });
          }
        });
      });
      return MERGED.filter(
        (device) =>
          device.configstate == "CONFIGURED" ||
          (device.configstate == "UNCONFIGURED" && device.edited)
      ).sort((a, b) =>
        a.last_temperature < b.last_temperature
          ? 1
          : b.last_temperature < a.last_temperature
          ? -1
          : 0
      );
    },
    DEVICES_COOL: function () {
      return this.DEVICES.filter(
        (device) =>
          device.tempsettings.sendtemperature && device.last_temperature <= 25
      );
    },
    DEVICES_MEDIUM: function () {
      return this.DEVICES.filter(
        (device) =>
          device.tempsettings.sendtemperature &&
          device.last_temperature > 25 &&
          device.last_temperature <= 35
      );
    },
    DEVICES_HOT: function () {
      return this.DEVICES.filter(
        (device) =>
          device.tempsettings.sendtemperature && device.last_temperature > 35
      );
    },
    DEVICES_MONITORED_RESPONDING: function () {
      return this.DEVICES.filter(
        (device) =>
          device.monitored == true &&
          new Date(device.last_contact_at).getTime() / 1000 >=
            new Date() / 1000 - 30 * 60
      );
    },
  },
  methods: {
    switchAutoUpdate: function () {
      if (this.enableAutoUpdate) {
        clearInterval(this.dashboardUpdater);
        this.enableAutoUpdate = false;
      } else {
        this.UpdateDashboard();
        this.enableAutoUpdate = true;
      }
    },
    UpdateDashboard: function () {
      let that = this;
      this.GET_DASHBOARD_DEVICES();
      that.GET_DASHBOARD_MEDIACOUNTER();
      this.dashboardUpdater = setInterval(function () {
        that.GET_DASHBOARD_MEDIACOUNTER();
        that.GET_DASHBOARD_DEVICES();
      }, 30 * 1000);
    },
    GET_DASHBOARD_MEDIACOUNTER: function () {
      this.$store.dispatch("GET_DASHBOARD_MEDIACOUNTER", {}).then(
        (response) => {
          this.MEDIA_COUNTER = response.data;
        },
        (error) => {
          if (error.response.status >= 400 && error.response.status <= 499) {
            this.hasError = true;
            localStorage.removeItem("USER_OBJECT");
            localStorage.removeItem("AUTH_HEADER");
            this.$store.commit("UPDATE_USER_OBJECT", null);
            this.$store.commit("UPDATE_AUTH_HEADER", null);
            this.$router.push("/");
          }
          if (error.response.status >= 500 && error.response.status <= 599) {
            alert("Internal Server Error :(");
          }
        }
      );
    },
    GET_DASHBOARD_DEVICES: function () {
      this.$store.dispatch("GET_DASHBOARD_DEVICES", {}).then(
        (response) => {
          this.DEVICES = response.data;
          this.$store.dispatch("GET_DASHBOARD_DEVICE_INFOSTORE", {}).then(
            (response) => {
              this.DEVICE_INFOS = response.data;
            },
            (error) => {
              if (
                error.response.status >= 400 &&
                error.response.status <= 499
              ) {
                this.hasError = true;
                localStorage.removeItem("USER_OBJECT");
                localStorage.removeItem("AUTH_HEADER");
                this.$store.commit("UPDATE_USER_OBJECT", null);
                this.$store.commit("UPDATE_AUTH_HEADER", null);
                this.$router.push("/");
              }
              if (
                error.response.status >= 500 &&
                error.response.status <= 599
              ) {
                alert("Internal Server Error :(");
              }
            }
          );
        },
        (error) => {
          if (error.response.status >= 400 && error.response.status <= 499) {
            this.loading = false;
            this.hasError = true;
            localStorage.removeItem("USER_OBJECT");
            localStorage.removeItem("AUTH_HEADER");
            this.$store.commit("UPDATE_USER_OBJECT", null);
            this.$store.commit("UPDATE_AUTH_HEADER", null);
            this.$router.push("/");
          } else if (
            error.response.status >= 500 &&
            error.response.status <= 599
          ) {
            alert("Internal Server Error :(");
          }
        }
      );
    },
  },
};
</script>
