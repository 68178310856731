<template>
  <div class="rounded-sm shadow-md text-gray-900 bg-white flex-row justify-between leading-tight">
    <div
      class="flex-1 h-2 rounded-sm rounded-b-none"
      v-bind:class="SET_BG_TEMP(device)"
    ></div>
    <div class="flex flex-row">
      <div class="flex px-2 py-1 whitespace-no-wrap">{{ device.playerid }}</div>
      <div class="flex-1 px-2 py-1 text-right">
        <span v-if="device.monitored">
          {{
            new Date(device.last_contact_at).getTime() / 1000 >=
            new Date() / 1000 - 30 * 60
              ? ""
              : "👀"
          }}
          {{ !device.undervolted ? "" : "⚡️" }}
          {{ !device.cpu_soft_temp_limited ? "" : "🔥" }}
          {{ !device.cpu_throttled ? "" : "🐢" }}
          {{ !device.tempsensorerror ? "" : "🌡" }}
          {{ device.device_config_applied ? "" : "⏳" }}
        </span>
        <span v-else>{{ device.device_config_applied ? "" : "⏳" }}</span>
      </div>
    </div>
    <div class="flex flex-row">
      <div class="flex px-2 py-1">{{ device.playersn }}</div>
      <div
        v-if="device.tempsettings.sendtemperature"
        class="flex-1 px-2 py-1 text-right whitespace-no-wrap"
      >
        <transition
          name="slide-fade"
          mode="out-in"
        >
          <div :key="device.last_temperature">
            {{ device.last_temperature.toFixed(2) }} °C
          </div>
        </transition>
      </div>
      <div
        v-else
        class="flex-1 px-2 py-1 text-right whitespace-no-wrap"
      >
        no rec
      </div>
    </div>
    <div class="flex flex-row">
      <div class="flex px-2 py-1">
        <transition
          name="slide-fade"
          mode="out-in"
        >
          <div :key="device.last_contact_at">
            <p>
              {{
                new Date(device.last_contact_at)
                  .toLocaleTimeString([], {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit"
                  })
                  .split(", ")
                  .join(" ")
              }}
            </p>
          </div>
        </transition>
      </div>
      <div class="flex-1 px-2 py-1 text-right">
        <transition
          name="slide-fade"
          mode="out-in"
        >
          <div :key="device.uptime">
            {{ (device.uptime / 60 / 60 / 24).toFixed(2) }} days
          </div>
        </transition>
      </div>
    </div>
    <div class="flex flex-row">
      <div class="flex-1 pl-2 py-1 text-left whitespace-no-wrap">
        <p>{{ device.location_from }}</p>
      </div>
      <div class="flex-1 py-1 text-center">=></div>
      <div class="flex-1 pr-2 py-1 text-right whitespace-no-wrap">
        <p>{{ device.location_to }}</p>
      </div>
    </div>
    <div
      class="flex-1 h-1 rounded-sm rounded-t-none"
      v-bind:class="SET_MONIT_COLOR(device)"
    ></div>
  </div>
</template>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>

<script>
export default {
  name: "DashboardDeviceConfigured",
  props: ["device"],
  data() {
    return {};
  },
  methods: {
    SET_BG_TEMP: function (device) {
      let bg_temp = "";
      if (!device.tempsettings.sendtemperature) {
        bg_temp = "bg-blue-200";
      } else if (device.last_temperature <= 25) {
        bg_temp = "bg-green-300";
      } else if (
        device.last_temperature > 25 &&
        device.last_temperature <= 35
      ) {
        bg_temp = "bg-yellow-300";
      } else if (device.last_temperature > 35) {
        bg_temp = "bg-red-400";
      }
      return bg_temp;
    },
    SET_MONIT_COLOR: function (device) {
      let bg_color = "";
      if (device.monitored) {
        bg_color = "bg-blue-400";
      } else {
        bg_color = "bg-blue-200";
      }
      return bg_color;
    },
  },
};
</script>
